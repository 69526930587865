var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-container',{staticClass:"hidden-md-and-up hidden-sm-and-up pa-0"}),_c('v-container',{staticClass:"reservations-history-container"},[_c('v-card',{staticStyle:{"box-shadow":"none !important"}},[_c('v-card-title',{staticClass:"reservations-history-card-title"},[_c('p',{staticClass:"reservations-history-header mb-0"},[_vm._v("ประวัติคำขอใช้พื้นที่")]),_c('v-spacer'),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"reservations-history-search-btn",attrs:{"outlined":"","dense":"","max-height":"45","placeholder":"ค้นหารายการประวัติ","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('p',{staticClass:"hidden-md-and-up hidden-sm-and-up reservations-history-request-space-title"},[_vm._v(" การขอใช้พื้นที่ ")]),(_vm.bookingHistory)?_c('div',[_c('v-data-table',{staticClass:"mytable",attrs:{"headers":_vm.headers,"items":_vm.bookingHistory,"search":_vm.search,"items-per-page":_vm.pagination.itemsPerPage,"header-props":{ 'sort-icon': 'mdi-menu-down' },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.summary",fn:function(ref){
var item = ref.item;
return [(item.applicantType != 'INTERNAL')?_c('div',[_vm._v(" "+_vm._s(item.summary)+" ")]):_vm._e()]}},{key:"item.remark",fn:function(ref){
var item = ref.item;
return [(item.remark)?_c('div',[_c('p',{staticClass:"mb-0 text--regular--14 reservations-history-view-remark",staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(item.remark)+" ")])]):_vm._e()]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [(item.detail.length > 0)?_c('div',[_c('p',{staticClass:"mb-0 text--regular--14 reservations-history-view-detail",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.moreDetail(item)}}},[_vm._v(" ดูรายละเอียด ")])]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"summary-button-mobile"},[_c('v-btn',{staticClass:"black--text summary-button",attrs:{"rounded":"","color":"primary","dark":"","elevation":"0"},on:{"click":function () { return _vm.onExportPdfFile(item); }}},[_vm._v(" ดาวน์โหลดแบบขอใช้ ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'ยังไม่ดำเนินการ')?_c('span',{staticStyle:{"color":"#BC1C1C"}},[_vm._v(_vm._s(item.status))]):_vm._e(),(
                item.status === 'รอการแก้ไขพื้นที่' ||
                  item.status === 'อยู่ระหว่างการตรวจสอบ' ||
                  item.status === 'ปรับเปลี่ยนพื้นที่' ||
                  item.status === 'ชำระเงินเรียบร้อย' ||
                  item.status === 'อนุญาต คำขอใช้พื้นที่' ||
                  item.status === 'ไม่อนุญาต คำขอใช้พื้นที่' ||
                  item.status === 'ยกเลิกโดยผู้ขอใช้พื้นที่'
              )?_c('span',{staticStyle:{"color":"#F4BC00"}},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'รอการชำระเงิน')?_c('span',{staticStyle:{"color":"#E08100"}},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'สำเร็จ')?_c('span',{staticStyle:{"color":"#65B52A"}},[_vm._v(_vm._s(item.status))]):_vm._e(),(item.status === 'ยกเลิก')?_c('span',{staticStyle:{"color":"#DDDDDD"}},[_vm._v(_vm._s(item.status))]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"text-center reservations-history-loadmore-btn"},[_c('v-btn',{staticClass:"reservations-history-btn",attrs:{"rounded":"","outlined":"","elevation":"0","height":"45","width":"229","disabled":!_vm.isFormValid},on:{"click":_vm.onLoadMore}},[_c('span',{staticStyle:{"padding":"12px 88px 12px 87px"}},[_vm._v("ดูเพิ่มเติม")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }