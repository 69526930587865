<template>
  <v-container class="pa-0">
    <v-container class="hidden-md-and-up hidden-sm-and-up pa-0">
      <!-- <VList></VList> -->
    </v-container>
    <v-container class="reservations-history-container">
      <v-card style="box-shadow: none !important;">
        <v-card-title class="reservations-history-card-title">
          <p class="reservations-history-header mb-0">ประวัติคำขอใช้พื้นที่</p>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="3" class="pa-0">
            <v-text-field
              v-model="search"
              class="reservations-history-search-btn"
              outlined
              dense
              max-height="45"
              placeholder="ค้นหารายการประวัติ"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-card-title>
        <p
          class="hidden-md-and-up hidden-sm-and-up reservations-history-request-space-title"
        >
          การขอใช้พื้นที่
        </p>
        <div v-if="bookingHistory">
          <v-data-table
            :headers="headers"
            :items="bookingHistory"
            :search="search"
            class="mytable"
            :items-per-page="pagination.itemsPerPage"
            :header-props="{ 'sort-icon': 'mdi-menu-down' }"
            hide-default-footer
          >
            <template v-slot:[`item.summary`]="{ item }">
              <div v-if="item.applicantType != 'INTERNAL'">
                {{ item.summary }}
              </div>
            </template>
            <template v-slot:[`item.remark`]="{ item }">
              <div v-if="item.remark">
                <p
                  style="cursor: pointer;"
                  class="mb-0 text--regular--14 reservations-history-view-remark"
                >
                  {{ item.remark }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.detail`]="{ item }">
              <div v-if="item.detail.length > 0">
                <p
                  @click="moreDetail(item)"
                  style="cursor: pointer;"
                  class="mb-0 text--regular--14 reservations-history-view-detail"
                >
                  ดูรายละเอียด
                </p>
              </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div class="summary-button-mobile">
                <v-btn
                  rounded
                  color="primary"
                  dark
                  elevation="0"
                  class="black--text summary-button"
                  @click="() => onExportPdfFile(item)"
                >
                  ดาวน์โหลดแบบขอใช้
                </v-btn>
              </div>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <span
                v-if="item.status === 'ยังไม่ดำเนินการ'"
                style="color: #BC1C1C;"
                >{{ item.status }}</span
              >
              <span
                v-if="
                  item.status === 'รอการแก้ไขพื้นที่' ||
                    item.status === 'อยู่ระหว่างการตรวจสอบ' ||
                    item.status === 'ปรับเปลี่ยนพื้นที่' ||
                    item.status === 'ชำระเงินเรียบร้อย' ||
                    item.status === 'อนุญาต คำขอใช้พื้นที่' ||
                    item.status === 'ไม่อนุญาต คำขอใช้พื้นที่' ||
                    item.status === 'ยกเลิกโดยผู้ขอใช้พื้นที่'
                "
                style="color: #F4BC00;"
                >{{ item.status }}</span
              >
              <span
                v-if="item.status === 'รอการชำระเงิน'"
                style="color: #E08100"
                >{{ item.status }}</span
              >
              <span v-if="item.status === 'สำเร็จ'" style="color: #65B52A">{{
                item.status
              }}</span>
              <span v-if="item.status === 'ยกเลิก'" style="color: #DDDDDD">{{
                item.status
              }}</span>
            </template>
          </v-data-table>
        </div>
        <div class="text-center reservations-history-loadmore-btn">
          <v-btn
            rounded
            outlined
            elevation="0"
            class="reservations-history-btn"
            height="45"
            width="229"
            @click="onLoadMore"
            :disabled="!isFormValid"
          >
            <span style="padding: 12px 88px 12px 87px;">ดูเพิ่มเติม</span>
          </v-btn>
        </div>
      </v-card>
    </v-container>
  </v-container>
  <!-- <v-container>
    <v-card style="box-shadow: none !important;">
      <v-card-title>
        <p class="reservations-history-header">ประวัติคำขอใช้พื้นที่</p>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="3">
          <v-text-field v-model="search" class="text--regular--14" outlined dense placeholder="ค้นหารายการประวัติ"
            append-icon="mdi-magnify"></v-text-field>
        </v-col>
      </v-card-title>
      <div v-if="bookingHistory">
        <v-data-table 
        :headers="headers" 
        :items="bookingHistory" 
        :search="search" class="mytable"
          :header-props="{ 'sort-icon': 'mdi-menu-down' }" 
          :sort-by="[sortById, sortByActivities, sortByCreatedDate]"
          :sort-desc.sync="sortDesc" 
          hide-default-footer>
          <template v-slot:headers="props">
            <tr>
              <th v-for="header in props.headers" :key="header.text" :class="[
                  'column sortable',
                  pagination.descending ? 'desc' : 'asc',
                  header.value === pagination.sortBy ? 'active' : ''
                ]" @click="changeSort(header.value)">
                <v-icon small>arrow_upward</v-icon>
                {{ header.text }}
              </th>
            </tr>
          </template>

          <template v-slot:[`item.detail`]="{ item }">
            <div v-if="item.detail.length > 0">
              <p @click="moreDetail(item)" style="cursor: pointer;" class="mb-0 text--regular--14">
                ดูรายละเอียด
              </p>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span v-if="item.status === 'ยังไม่ดำเนินการ'" style="color: #BC1C1C;">{{ item.status }}</span>
            <span v-if="item.status === 'สำเร็จ'" style="color: #65B52A">{{
              item.status
            }}</span>
            <span v-if="item.status === 'รอการชำระเงิน'" style="color: #E08100">{{ item.status }}</span>
            <span v-if="item.status === 'ยกเลิก'" style="color: #DDDDDD">{{
              item.status
            }}</span>
          </template>
        </v-data-table>
      </div>
       <div class="text-center" style="margin-top:73px">
          <v-btn
            rounded
            outlined
            elevation="0"
            class="reservations-history-btn"
            height="45"
            width="229"
            @click="onSeeMore"
          >
            <span style="padding: 12px 88px 12px 87px;"
              >ดูเพิ่มเติม</span
            >
          </v-btn>
      </div>

    </v-card>
  </v-container> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _get from "lodash/get";
import _filter from "lodash/filter";
// import VList from "../components/VList.vue";

export default {
  // components: {
  //   VList
  // },
  computed: {
    ...mapGetters("authentication", {
      parseJwt: "parseJwt"
    }),
    ...mapGetters("booking", {
      bookingHistory: "getDisplayBookingHistory",
      bookingFullItemHistory: "getBookingByBookingNo"
    })
  },
  data: () => ({
    sortByActivities: "activities",
    sortByCreatedDate: "created_date",
    sortById: "id",
    sortDesc: false,
    search: "",
    headers: [
      {
        text: "#",
        align: "start",
        value: "id",
        sortable: false
      },
      {
        text: "ชื่อหัวข้อกิจกรรม",
        align: "start",
        sortable: true,
        value: "activities"
      },
      {
        text: "วันที่ทำรายการ",
        value: "created_date",
        sortable: true
      },
      {
        text: "ราคา",
        value: "summary",
        sortable: false
      },
      {
        text: "สถานะ",
        value: "status",
        sortable: false
      },
      {
        text: "หมายเหตุ",
        value: "remark",
        sortable: false
      },
      {
        text: "",
        value: "detail",
        sortable: false
      },
      {
        text: "",
        value: "action",
        sortable: false
      }
    ],
    pagination: {
      sortBy: null,
      descending: false,
      page: 1,
      itemsPerPage: 10
    },
    // Start : Sasiprapa B. : 2021-05-03
    isFormValid: true
    // End : Sasiprapa B. : 2021-05-03
  }),
  async created() {
    let parseJwtResult = this.parseJwt;
    const filters = {
      order: "id asc",
      limit: 999,
      where: {
        userId: _get(parseJwtResult, "id", "")
      },
      fields: {
        id: true,
        applicantType: true,
        department_name: true,
        group_name: true,
        tel: true,
        activities_detail: true,
        setup_start_date: true,
        setup_start_time: true,
        setup_end_time: true,
        remove_start_date: true,
        remove_start_time: true,
        remove_end_time: true,
        event_start_time: true,
        event_end_time: true,
        equipment: true,
        parking: true,
        userId: true,
        zipcode: true,
        thname: true,
        enname: true,
        address: true,
        subdistrict: true,
        province: true,
        district: true,
        mobile: true,
        activities: true,
        activityImageList: true,
        isRequireAccessory: true,
        status: true,
        created_date: true,
        updated_date: true,
        maintenanceCost: true,
        summary: true,
        depositPrice: true,
        bookingNo: true,
        remark: true
      },
      include: [
        {
          relation: "bookingItems",
          scope: {
            order: "id asc",
            include: [
              {
                relation: "room",
                scope: {
                  order: "id asc"
                }
              }
            ]
          }
        }
      ]
    };
    if (this.$route.params.param) {
      let decodeParam = decodeURIComponent(this.$route.params.param);
      let decodeBase64Param = atob(decodeParam);
      let json = JSON.parse(decodeBase64Param);
      let objParam = {
        ...json
      };

      switch (objParam.status) {
        case "pendding":
          objParam.status = "รอการแก้ไขพื้นที่";
          break;
        case "cancel":
          objParam.status = "ยกเลิก";
          break;
        default:
          break;
      }

      let updateBookingResponse = await this.updateBookingStatus(objParam);
      if (updateBookingResponse.status == 204) {
        this.getBookingHistory(filters);
      }
    } else {
      this.getBookingHistory(filters);
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.bookingHistory && this.$route.params.param) {
        if (this.bookingHistory.length > 0 && this.$route.params.param) {
          let decodeParam = decodeURIComponent(this.$route.params.param);
          let decodeBase64Param = atob(decodeParam);
          let json = JSON.parse(decodeBase64Param);
          let objParam = {
            ...json
          };

          let filterBookingId = _filter(
            this.bookingHistory,
            o => _get(o, "detail[0].bookingId", "") === objParam.bookingId
          );
          if (filterBookingId.length > 0) {
            this.moreDetail(filterBookingId[0]);
          }
        }
      }
    });
  },
  mounted() {},
  methods: {
    ...mapActions("booking", ["getBookingHistory", "updateBookingStatus"]),
    ...mapActions("booking", ["setBookingDetailItemsSelcted"]),
    ...mapActions("app", ["setBackgroundFormStepperScreen"]),
    ...mapActions("exportpdf", ["exportFilePDF"]),
    moreDetail(data) {
      this.setBookingDetailItemsSelcted(data);
      this.$router.push("/detail");
      this.setBackgroundMain(); //Start : set background main
    },
    onLoadMore() {
      if (this.pagination.itemsPerPage < this.bookingHistory.length) {
        this.pagination.itemsPerPage += 10;
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    },
    setBackgroundMain() {
      this.setBackgroundFormStepperScreen(2);
    },
    onExportPdfFile(param) {
      if (param) {
        let getBookingNo = _get(param, "bookingNo", []);
        let result = this.bookingFullItemHistory(getBookingNo);
        if (result) {
          let getBooking = _get(result, "[0]", []);
          this.exportFilePDF(getBooking);
        }
      }
    }
  }
};
</script>
<style scoped>
.reservations-history-header {
  font-weight: 700;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
  line-height: 35px;
}

.reservations-history-table-info {
  font-weight: 400;
  font-size: 14px;
}

.reservations--history--table--header {
  color: #282828;
}

.reservations-history-btn {
  color: #282828;
  font: normal normal normal 14px/21px Kanit;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0px;
}

.reservations-history-view-detail {
  color: #282828;
  text-decoration: underline;
  letter-spacing: 0;
  line-height: 21px;
}
.reservations-history-view-remark {
  color: #282828;
  letter-spacing: 0;
  line-height: 21px;
}

.reservations-history-container {
  padding-top: 78.5px;
  padding-bottom: 0px;
}

.reservations-history-card-title {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

.reservations-history-search-btn {
  font-weight: 400;
  font-size: 14px;
}

.reservations-history-loadmore-btn {
  margin-top: 73px;
  margin-bottom: 216.5px;
}

.reservations-history-request-space-title {
  font-weight: 700;
  font-size: 16px;
  color: #282828;
  margin-bottom: 13px;
  margin-top: 27px;
}

/* mobile */
@media (max-width: 600px) {
  .reservations-history-container {
    padding-top: 30px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .reservations-history-header {
    font-size: 20px;
    line-height: 26px;
    height: 30px !important;
    width: 161px !important;
  }

  .reservations-history-search-btn {
    margin-top: 13px !important;
  }

  .reservations-history-loadmore-btn {
    margin-top: 51px !important;
    margin-bottom: 79px !important;
  }

  .reservations-history-card-title {
    padding-bottom: 0px;
  }
  .summary-button-mobile {
    padding-bottom: 20px !important;
  }
}
</style>
